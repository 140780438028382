.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .7);
  z-index: 2;
}

video,
.ie8-poster {
  z-index: 1;
  background-color: rgba(0, 0, 0, .7);

}

.text-copy {
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 2;
  text-shadow: 1px 1px 1px #000;
}

