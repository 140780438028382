div.nav-active .nav__content {
	visibility: visible;
}
div.nav-active .menu-icon__line {
	background-color: #fff;
	-webkit-transform: translate(0px, 0px) rotate(-45deg);
          transform: translate(0px, 0px) rotate(-45deg);
}
div.nav-active .menu-icon__line-left {
	width: 15px;
	-webkit-transform: translate(2px, 4px) rotate(45deg);
          transform: translate(2px, 4px) rotate(45deg);
}
div.nav-active .menu-icon__line-right {
	width: 15px;
	float: right;
	-webkit-transform: translate(-3px, -3.5px) rotate(45deg);
          transform: translate(-3px, -3.5px) rotate(45deg);
}
div.nav-active .menu-icon:hover .menu-icon__line-left,
div.nav-active .menu-icon:hover .menu-icon__line-right,
div.nav-active .menu-icon:focus .menu-icon__line-left,
div.nav-active .menu-icon:focus .menu-icon__line-right  {
	width: 15px;
}
div.nav-active .nav {
	visibility: visible;
}
div.nav-active .nav:before, div.nav-active .nav:after {
	-webkit-transform: translateX(0%) translateY(0%);
          transform: translateX(0%) translateY(0%);
	border-radius: 0;
}
div.nav-active .nav:after {
	-webkit-transition-delay: .1s;
          transition-delay: .1s;
}
div.nav-active .nav:before {
	-webkit-transition-delay: 0s;
          transition-delay: 0s;
}
div.nav-active .nav__list-item {
	opacity: 1;
	-webkit-transform: translateX(0%);
          transform: translateX(0%);
	-webkit-transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
	transition: opacity .3s ease, color .3s ease, -webkit-transform .3s ease;
	transition: opacity .3s ease, transform .3s ease, color .3s ease;
	transition: opacity .3s ease, transform .3s ease, color .3s ease, -webkit-transform .3s ease;
}
div.nav-active .nav__list-item:nth-child(0) {
	-webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}
div.nav-active .nav__list-item:nth-child(1) {
	-webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}
div.nav-active .nav__list-item:nth-child(2) {
	-webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}
div.nav-active .nav__list-item:nth-child(3) {
	-webkit-transition-delay: 1s;
          transition-delay: 1s;
}
div.nav-active .nav__list-item:nth-child(4) {
	-webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}
div.nav-active .nav__list-item:nth-child(5) {
	-webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}
div.nav-active .nav__list-item:nth-child(6) {
	-webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}
div.nav-active .nav__list-item:nth-child(7) {
	-webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}
div.nav-active .nav__list-item:nth-child(8) {
	-webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}
div.nav-active .nav__list-item:nth-child(9) {
	-webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}
div.nav-active .nav__list-item:nth-child(10) {
	-webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}





.nav__content {
	position: fixed;
	visibility: hidden;
	top: 50%;
	margin-top: 20px;
	-webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
	width: 100%;
	text-align: center;
}
.nav__list {
	position: relative;
	padding: 0;
	margin: 0;
	z-index: 2;
}
.nav__list-item {
	position: relative;
	display: block;
	-webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
	opacity: 0;
	text-align: center;
	color: #fff;
	overflow: hidden; 
	font-size: 5rem;
	font-weight: 900;
	line-height: 1.15;
	letter-spacing: 3px;
	-webkit-transform: translate(100px, 0%);
          transform: translate(100px, 0%);
	-webkit-transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, -webkit-transform .3s ease;
	transition: opacity .2s ease, transform .3s ease;
	transition: opacity .2s ease, transform .3s ease, -webkit-transform .3s ease;
	margin-top: 0;
	margin-bottom: 0;
}
.nav__list-item button{ 
	background-color: transparent;
    border: none;
	outline: none;

	position: relative;
	text-decoration: none;
	color: rgba(255,255,255,0.6);
	overflow: hidden; 
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
	font-weight: 900;
	z-index: 2;
	display: inline-block;
	text-transform: uppercase;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}
.nav__list-item button:after{ 
	position: absolute;
	content: '';
	top: 50%;
	margin-top: -2px;
	left: 50%;
	width: 0;
	height: 0;
	opacity: 0;
	background-color: var(--second-color);
	z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; 
}

.nav__list-item{
	outline-width: 0;
}

.nav__list-item button:hover:after,
.nav__list-item:focus button:after{ 
	height: 4px;
	opacity: 1;
	left: 0;
	width: 100%;
}
.nav__list-item button:hover,
.nav__list-item:focus button{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav button{
	color: rgba(255,255,255,1);
}
.nav__list-item.active-nav button:after{ 
	height: 4px;
	opacity: 1;
	left: 0;
	width: 100%;
}